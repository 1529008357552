import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`My very first program was written in `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://en.wikipedia.org/wiki/GW-BASIC"}}>{`GW-BASIC`}</MDXTag>{`
and did a very simple thing.`}</MDXTag>
<MDXTag name="p" components={components}>{`You could enter your name and it would respond with a greeting:`}</MDXTag>
<div className="gatsby-highlight" data-language="text"><pre style={{counterReset: 'linenumber NaN'}} className="language-text line-numbers"><code className="language-text">What is your name? Nicolo{"\n"}Hello Nicolo!</code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /></span></pre></div>

<MDXTag name="p" components={components}>{`Even this trivial program contained something that I cherish
in code that I write today: `}<MDXTag name="em" components={components} parentName="p">{`the fact that your creation seems
to be alive and respond in ways that are dependent on external
input.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/frankenstein.jpg","alt":"Frankenstein"}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This is not a thrill that is exclusively enjoyed by programmers.
There are a lot of things that we do on a regular basis
that are in fact programming
(they just don’t involve writing code).`}</MDXTag>
<MDXTag name="p" components={components}>{`Setting your oven to turn off automatically after 30 minutes is
a form of programming. So is an Excel formula or playing `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://factorio.com/"}}>{`Factorio`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`My latest project `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgamelab.app/"}}>{`Board Game Lab`}</MDXTag>{` is an attempt to
allow people with no programming experience to express the rules of
their board game to a platform (without writing code) in order
to have it be played by bots (this is useful
for playtesting your game before you get it in front of
human players).`}</MDXTag>
<MDXTag name="p" components={components}>{`More Frankenstein moments for all!`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  